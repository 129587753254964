
import React from 'react';
import axios from 'axios';
import { object, string, ref } from 'yup';
import { useFormik } from 'formik';
import { Grid,  FormLabel, RadioGroup, Radio,DialogTitle, IconButton, Box, TextField, Alert, AlertTitle, FormControl, Select, MenuItem, FormControlLabel, InputLabel, Button, Divider, Dialog, useMediaQuery, useTheme, DialogContent, FormHelperText} from '@mui/material';
import {  useState } from 'react';
import AlertClosable from 'AlertClosable';
import {CREDITS, ERRORS } from './credits'
import { Close } from '@mui/icons-material';

export function NewInvitation({handleClose, handleSuccess}) {
    const theme = useTheme();
    const [isPosting, setIsPosting] = useState(false)
    const [error, setError] = useState(null)
    
    const invitationSchema = object({
        name: string().required('Le nom est requis'),
        email: string().email('Email invalide').required('L\'email est requis'),
        emailConfirmation: string().email('Email invalide').required('La confirmation de l\'email est requis').oneOf([ref('email'), null], 'Les emails doivent correspondre'),
        phone: string().required('Requis'),
        address: string().required('Requis'),
        zipCode: string().required('Requis'),
        city: string().required('Requis'),
        credits: string().required('L\'offre est requise'),
        option: string().required('L\'option est requise'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            emailConfirmation: '',
            phone: '',
            address: '',
            zipCode: '',
            city: '',
            credits: 100,
            option: 'none',
        },
        validationSchema: invitationSchema,
        onSubmit: async (values) => {
            setIsPosting(true)
            return axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/partners/invitations`,values
                ).then(resp => {
                    setIsPosting(false);
                    setError(null);    
                    console.log(resp.data) 
                    handleSuccess(resp.data)       
                }).catch(err => {
                    console.log(err.response)
                    setIsPosting(false)
                    if (err.response.status === 400 ) {
                        console.log(Object.keys(ERRORS).includes(err.response.data.code))
                        if ( Object.keys(ERRORS).includes(err.response.data.code)) {
                            setError(ERRORS[err.response.data.code])
                        } else {
                            setError("Erreur inconnue, veuillez nous excuser ... Vous pouvez nous contacter sur contact@sportpxl.com")
                        }
                    } else {
                        setError("Erreur inconnue, veuillez nous excuser ... Vous pouvez nous contacter sur contact@sportpxl.com")
                    }
            })
        }
    })

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog open={true} fullWidth maxWidth="md" fullScreen={fullScreen}>
                  <DialogTitle>
            <Box display="flex" alignItems={"center"}>
              <Box flexGrow={1}>Inviter un Évenement / Photographe</Box>
              <Box>
                    <IconButton onClick={handleClose}>
                          <Close />
                    </IconButton>
                </Box>
            </Box>
          </DialogTitle>
        <DialogContent>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AlertClosable title="Comment ça marche ?" message=" L'invitation sur SportPXL est automatique après la soumission du formulaire.
                         L'événement ou le photographe invité recevra un email pour s'inscrire et disposera du nombre de crédit de l'offre choisie. Si une option diplôme est choisie, le prix dépendra du nombre de participants." />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField required fullWidth size="small" id="outlined-basic" label="Nom" variant="outlined" 
                        name="name"
                         value={formik.values.name}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         error={formik.touched.name && Boolean(formik.errors.name)}
                         helperText={formik.touched.name && formik.errors.name}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField required variant="outlined" size="small" id="outlined-basic" label="Email"   name="email"
                         value={formik.values.email}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         error={formik.touched.email && Boolean(formik.errors.email)}
                         helperText={formik.touched.email && formik.errors.email}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField required variant="outlined" size="small" id="outlined-basic" label="Confirmation de l'Email" 
                            name="emailConfirmation"
                            value={formik.values.emailConfirmation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.emailConfirmation && Boolean(formik.errors.emailConfirmation)}
                            helperText={formik.touched.emailConfirmation && formik.errors.emailConfirmation}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField  
                        fullWidth size="small" id="outlined-basic" label="Téléphone" variant="outlined" name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField  
                        fullWidth size="small" id="outlined-basic" label="Adresse" variant="outlined" name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField  
                        fullWidth size="small" id="outlined-basic" label="Code postal" variant="outlined" name="zipCode"
                        value={formik.values.zipCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                        helperText={formik.touched.zipCode && formik.errors.zipCode}
                    />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField 
                        fullWidth size="small" id="outlined-basic" label="Ville" variant="outlined"  name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth
                        error={formik.touched.credits && Boolean(formik.errors.credits)}
                    >
                        <InputLabel id="chosen-offer-label">Offre choisie *</InputLabel>
                        <Select 
                            fullWidth required variant="outlined" size="small" id="chosen-offer" labelId="chosen-offer-label" label={"Offre choisie *"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="credits"
                            value={formik.values.credits}
                            error={formik.touched.credits && Boolean(formik.errors.credits)}
                        >
                            <MenuItem value={100} >Gratuit / 100 crédits</MenuItem>
                            <MenuItem value={1000}>Offre 1k / 24.90 € / 1000 crédits</MenuItem>
                            <MenuItem value={5000}>Offre 5k / 99 € / 5000 crédits</MenuItem>
                            <MenuItem value={10000}>Offre 10k / 199 € / 10000 crédits</MenuItem>
                            <MenuItem value={30000}>Offre 30k / 399 € / 30000 crédits</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        error={formik.touched.option && Boolean(formik.errors.option)}
                    >
                        <FormLabel id="demo-controlled-radio-buttons-group">Option</FormLabel>
                        {(formik.touched.option && Boolean(formik.errors.option)) && (
                            <FormHelperText error>{formik.touched.option && Boolean(formik.errors.option)}</FormHelperText>
                        )}
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="option"
                            value={formik.values.option}
                            onChange={formik.handleChange}
                          
                        >
                            <FormControlLabel value="none" control={<Radio />} label="Aucun diplôme" />
                            <FormControlLabel value="generic" control={<Radio />} label="Diplôme générique à 0.30€ / participant" />
                            <FormControlLabel value="custom" control={<Radio />} label="Diplôme personnalisé à 0.70€ / participant" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} textAlign="center">
                        <Alert severity='info'>
                        <AlertTitle>Prix total facturé :</AlertTitle>
                        {CREDITS[formik.values.credits].price} € + ({formik.values.option === 'none' ? 0 : formik.values.option === 'generic' ? 0.20 : 0.70} € x participant)
                    </Alert>
                    
                </Grid>
                { error && (
                    <Grid item xs={12}>
                        <Alert severity='error'>
                            <AlertTitle>Erreur lors de la création</AlertTitle>
                            {error}
                        </Alert>
                    </Grid>)}
                <Grid item xs={8}>
                    <Button variant="contained" disabled={isPosting} color="primary" fullWidth onClick={() => formik.handleSubmit()}>
                        Envoyer l'invitation {isPosting && '...'}
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" color="warning" fullWidth onClick={handleClose}>
                        Annuler
                    </Button>
                </Grid>
            </Grid>
    </DialogContent>
    </Dialog>
  );
}

export default NewInvitation;
